import { useTranslation } from '@pancakeswap/localization'
import { ArrowDownIcon, Button, Text } from '@pancakeswap/uikit'
import { memo } from 'react'

import { AutoRow } from 'components/Layout/Row'
import { useSwapState } from 'state/swap/hooks'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'

import AddressInputPanel from '../../components/AddressInputPanel'
import { ArrowWrapper } from '../../components/styleds'
import { useAllowRecipient } from '../hooks'

export const Recipient = memo(function Recipient() {
  const { t } = useTranslation()
  const { recipient } = useSwapState()
  const { onChangeRecipient } = useSwapActionHandlers()
  const allowRecipient = useAllowRecipient()

  if (!allowRecipient || recipient === null) {
    return null
  }

  return (
    <>
      <AutoRow justify="space-between" style={{ padding: '1rem 0' }}>
        <ArrowWrapper clickable={false}>
          <ArrowDownIcon color="black" width="36px" />
        </ArrowWrapper>
        <Button variant="text" px="0" id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
          <Text bold>{t('- Remove send')}</Text>
        </Button>
      </AutoRow>
      <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
    </>
  )
})
